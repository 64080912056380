<template>
  <div class="payResultNotify">
    <div class="logoImg"> <img src="../../../assets/weixinlogo.png"></div>
    <div class="notify-content">
      <span>{{ '扫码付款' || $t('screanPay') }}</span>
      <span class="money" v-if="money">￥{{ money }}</span>
      <img v-if="qrCodeUrl" :src="qrCodeUrl" alt="">
      <img src="../../../assets/weixinsaoma.png" class="weipay" alt="">
    </div>
    <div class="btns" style="text-align:center">
      <label @click="checkWXPayStatus">{{ '验证支付结果' || $t('checkPayStatus') }}</label>
    </div>
  </div>
</template>
  
  <script>
import { getUserAuth_fetch, checkPayResult_fetch } from "@/api/newVersion/vip"
export default {
  data() {
    return {
      qrCodeUrl: '',
      money: 0,
      rechargeId: '',
      auth: {}
    };
  },
  watch: {
    // 监控是否已经更新状态管理器的签名
    '$store.state.auth': {
      handler(val) {
        this.auth = this.$store.state.auth;
        this.auth.Authorization = 'Authorization ' + this.auth.token;
      },
      deep: true
    }
  },
  created() {
    this.qrCodeUrl = this.$route.query.QRcodeUrl;
    this.money = this.$route.query.money;
    this.rechargeId = this.$route.query.rechargeId;
  },
  methods: {
    async checkWXPayStatus() {
      let self = this;
      let params = {
        rechargeId: this.rechargeId
      };

      let body = this.getPostParams(params, this.$store.state.auth.auth.randomKey);
      let auth = await this.handlerGetAuth()
      const result = await checkPayResult_fetch(body, auth.token);
      if (result && result.code === 200) {
        this.$message({
          type: "success",
          message: result.message
        });
      } else if (result && result.data.code === 700) {
        this.common.handlerRetSetAuth(result.data, this.checkWXPayStatus)
      } else {

      }
    },
    // 获取新的token
    async handlerGetAuth() {
      let params = {
        userName: this.$store.getters.userInfo.id,
        password: this.$store.getters.userInfo.token
      }
      let result = null;
      let base64Str = Base64.encode(JSON.stringify(params))
      // result = await getUserAuth({
      //   user: base64Str
      // })
      let formData = new FormData()
      formData.append('user', base64Str)
      result = await getUserAuth_fetch('auth', formData)
      return result
    },
  }
};
</script>
  
  <style lang="scss">
  .payResultNotify {
    display: block;
    width: 100%;
  
    .logoImg {
      text-align: left;
      margin: 1.25rem;
    }
  
    .notify-content {
      display: flex;
      flex-flow: column;
      position: relative;
      // min-height: 30vh;
      align-items: center;
      margin-bottom: 1.2rem;
      font-size: 14px;
  
      .money {
        font-size: 40px;
        font-weight: 600;
        margin-top: 0.3rem;
        color: #33cc66;
      }
  
      .weipay {
        position: absolute;
        right: 15%;
        // top: 15%;
      }
    }
  
    .btns {
      label {
        background: #33cc66;
        color: #ffffff;
        padding: 0.5rem;
        font-size: 12px;
        border-radius: 0.2rem;
        cursor: pointer;
      }
    }
  }
  </style>
  