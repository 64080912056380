var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payResultNotify" }, [
    _vm._m(0),
    _c("div", { staticClass: "notify-content" }, [
      _c("span", [_vm._v(_vm._s("扫码付款" || _vm.$t("screanPay")))]),
      _vm.money
        ? _c("span", { staticClass: "money" }, [
            _vm._v("￥" + _vm._s(_vm.money))
          ])
        : _vm._e(),
      _vm.qrCodeUrl
        ? _c("img", { attrs: { src: _vm.qrCodeUrl, alt: "" } })
        : _vm._e(),
      _c("img", {
        staticClass: "weipay",
        attrs: { src: require("../../../assets/weixinsaoma.png"), alt: "" }
      })
    ]),
    _c(
      "div",
      { staticClass: "btns", staticStyle: { "text-align": "center" } },
      [
        _c("label", { on: { click: _vm.checkWXPayStatus } }, [
          _vm._v(_vm._s("验证支付结果" || _vm.$t("checkPayStatus")))
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logoImg" }, [
      _c("img", { attrs: { src: require("../../../assets/weixinlogo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }